import Project from "../Project/Project";
import Filters from "../Filters/Filters";
import Loading from "../Loading/Loading";
import "./Projects.css";
import { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { motion, AnimatePresence } from "framer-motion";

const Projects = () => {
  const [allProjects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [activeFilter, setActiveFilter] = useState("Todos");
  const [isLoading, setLoading] = useState(true);

  const buscarProjects = (dato) => {
    const qs = require("qs");
    const query = qs.stringify(
      {
        populate: ["tags"],
      },
      {
        encodeValuesOnly: true,
      }
    );

    fetch(
      `https://dezetapi-production.up.railway.app/api/projects?populate=*`,
      {
        method: "get",
        headers: new Headers({
          Authorization:
            "Bearer 1d82fb7af892faae9a5adf1d277909f1404b02e29d71d572fefad3e4d99ab8f427c35930f41e8d56f1d083afac263bc9512bdc80424ea0df90d86d83be48d7d1faaa3160a554c6905b0c0fd47e9e2458678d2a4e3ea418d711067b129b1ff6f5594337b0be839beb0c1bf95d8ce345546615eb9c61db918156d6b320d87759aa",
          "Content-Type": "application/json; charset=utf-8",
          "Access-Control-Allow-Origin": "*",
        }),
      }
    )
      .then((r) => r.json())
      .then((res) => {
        setProjects(res.data);
        setFilteredProjects(res.data);
        setLoading(false);
      });
  };

  useEffect(() => {
    buscarProjects();
  }, []);

  return (
    <section id="portfolio">
      <div className="container-90">
        <h2>PORTFOLIO</h2>
        <Filters
          allProjects={allProjects}
          setFilteredProjects={setFilteredProjects}
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
        />
        <Container fluid>
          <motion.div layout className="row">
            <AnimatePresence>
              {isLoading ? (
                <Loading />
              ) : filteredProjects.length !== 0 ? (
                filteredProjects.map((listaProject) => (
                  <Project key={listaProject.id} {...listaProject} />
                ))
              ) : (
                <p>No hay resultados</p>
              )}
            </AnimatePresence>
          </motion.div>
        </Container>
      </div>
    </section>
  );
};

export default Projects;
