import React from 'react'
import './Footer.css';
const Footer = () => {
  return (
    <footer className='bg-texture-blue' id='footer'>
      <div className='container-90'>
        <h5>Darío Zalovich</h5>
        <div className='data'>
          <div>
            <p>Montevideo, Uruguay</p>
            <a href='tel:+59895271279'>095 271 279</a>
            <a href='mailto:darozalovich@gmail.com'>darozalovich@gmail.com</a>
          </div>
          <div>
            <a href='https://www.instagram.com/dezetaportfolio/'>Instagram</a>
            <a href='https://www.linkedin.com/in/dariozalovich/'>Linkedin</a>
            <a href='https://vimeo.com/713360962'>Vimeo</a>
          </div>
        </div>
        
       
      </div>
    </footer>
  )
}

export default Footer