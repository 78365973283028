import Projects from "../Projects/Projects";
import About from "../About/About";
import BackToTop from "../BackToTop/BackToTop";

const Home = () => {
  document.title = `Portfolio - Dario Zalovich`;

  return (
    <>
      <About />
      <Projects />
      <BackToTop />
    </>
  );
};

export default Home;
