import React from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import logo from "../../img/logo.svg";

const Header = () => {
  return (
    <header>
      <div className="container-90">
        <Link to={{ pathname: "/" }}>
          <img className="logo" src={logo} alt="logo"></img>
        </Link>
        <nav>
          <HashLink to="/#about">Sobre mí</HashLink>
          <HashLink to="/#portfolio">Portfolio</HashLink>
          <HashLink to="#footer">Contacto</HashLink>
        </nav>
      </div>
    </header>
  );
};

export default Header;
