import React from "react";
import { useEffect } from "react";
import "./About.css";
import profile from "../../img/profile.png";
import nexus from "../../img/nexus.svg";
import { HashLink } from "react-router-hash-link";
import AOS from "aos";
import "aos/dist/aos.css";

const About = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <>
      <section className="mainBanner">
        <HashLink to="/#about">
          <div className="darioZalovich">
            <h2>DARÍO</h2>
            <div className="blueNexus"></div>
            <h2>ZALOVICH</h2>
          </div>
        </HashLink>
      </section>
      <section id="about" className="nexusCreate aboutInfo">
        <div className="data">
          <h4 className="aboutTitle">Diseño para crear</h4>
          <h4 className="aboutBigWord">
            <svg viewBox="0 0 1000 90" xmlns="http://www.w3.org/2000/svg">
              <text
                dominantBaseline="hanging"
                stroke="#0000C1"
                fill="white"
                strokeWidth="3"
                paintOrder="stroke"
              >
                NEXOS
              </text>
            </svg>
          </h4>
          <p>
            El diseño busca crear{" "}
            <span className="strong">
              nexos.<br></br>
            </span>{" "}
            Nexos para conectar distintos mundos que deben ser unidos.
          </p>
        </div>
        <div className="extra bg-texture-grey"></div>
        <div className="nexus">
          <img data-aos="slide-right" src={nexus} alt="nexos"></img>
        </div>
      </section>
      <section className="aboutInfo worlds bg-texture-blue">
        <img
          className="profile"
          data-aos="slide-right"
          src={profile}
          alt="Dario  Zalovich"
        ></img>
        <div className="data">
          <h4 className="aboutTitle">Diseño para unir</h4>
          <h4 className="aboutBigWord">MUNDOS</h4>
          <p>
            Mi objetivo como diseñador es seguir creando{" "}
            <span className="strong">soluciones</span> que permitan aportar
            valor a distintos usuarios y{" "}
            <span className="strong">unir nuevos mundos</span>.
          </p>
        </div>
      </section>
    </>
  );
};

export default About;
