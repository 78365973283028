import { useState, useEffect } from "react";
import ErrorPage from "../ErrorPage/ErrorPage";
import BackToTop from "../BackToTop/BackToTop";
import Loading from "../Loading/Loading";
import "./ExpandedProject.css";
import AOS from "aos";
import "aos/dist/aos.css";

const Home = ({ project }) => {
  const [projectDetails, setProjectsDetails] = useState([]);
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const buscarProjects = (dato) => {
    fetch(
      `https://dezetapi-production.up.railway.app/api/projects?filters[title][$eq]=${project}&populate=*`,
      {
        method: "get",
        headers: new Headers({
          Authorization:
            "Bearer 1d82fb7af892faae9a5adf1d277909f1404b02e29d71d572fefad3e4d99ab8f427c35930f41e8d56f1d083afac263bc9512bdc80424ea0df90d86d83be48d7d1faaa3160a554c6905b0c0fd47e9e2458678d2a4e3ea418d711067b129b1ff6f5594337b0be839beb0c1bf95d8ce345546615eb9c61db918156d6b320d87759aa",
        }),
      }
    )
      .then((r) => r.json())
      .then((res) => {
        setProjectsDetails(res?.data[0]?.attributes);
        setLoading(false);
        document.title = `${res?.data[0]?.attributes.title} - Dario Zalovich`;
      });
  };

  useEffect(() => {
    buscarProjects();
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="loadingBig">
          <Loading />
        </div>
      ) : projectDetails ? (
        <div>
          <section className=" container-90 sect-one">
            <div className="texts">
              <h2>{projectDetails.title}</h2>
              <h4>{projectDetails.description}</h4>
              <section className="details">
                {projectDetails.date ? (
                  <>
                    <div>
                      <p>Fecha</p>
                      <h5>{projectDetails.date}</h5>
                    </div>
                  </>
                ) : (
                  ""
                )}
                {projectDetails.subject ? (
                  <>
                    <div>
                      <p>Materia</p>
                      <h5>{projectDetails.subject}</h5>
                    </div>
                  </>
                ) : (
                  ""
                )}
                {projectDetails.coautors ? (
                  <>
                    <div>
                      <p>Equipo</p>
                      <h5>{projectDetails.coautors}</h5>
                    </div>
                  </>
                ) : (
                  ""
                )}
                {projectDetails.contentUrl ? (
                  <>
                    <div>
                      <p>Contenido</p>
                      <h5>
                        <a href={projectDetails.contentUrl} target="_blank">
                          Visitar web
                        </a>
                      </h5>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </section>
            </div>
            <img
              className="image"
              data-aos="slide-left"
              src={`${projectDetails.header?.data.attributes.url}`}
              alt="project"
            ></img>
          </section>

          {projectDetails?.steps?.projects ? (
            <section className=" container-90 sect-two">
              {projectDetails?.steps?.projects.map((step, i) => (
                <div className="step-card" key={i}>
                  <div
                    className="step-card-image"
                    style={{
                      background: `url(${projectDetails.stepsImg?.data[i].attributes.url})`,
                    }}
                  ></div>
                  <h3>{step.title}</h3>
                  <p>{step.description}</p>
                </div>
              ))}
            </section>
          ) : (
            ""
          )}

          <section className="sect-three">
            {projectDetails?.video ? (
              <iframe
                width="1920"
                height="1080"
                src={`https://www.youtube.com/embed/${projectDetails.video}?autoplay=1&showinfo=0&mute=1&loop=1&playlist=${projectDetails.video}&controls=0&modestbranding=1&rel=0
                  `}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              ></iframe>
            ) : projectDetails?.header ? (
              <img
                className="image"
                src={`${projectDetails.header?.data.attributes.url}`}
                alt="project header"
              ></img>
            ) : (
              ""
            )}
          </section>
          <section className="container-90 sect-four">
            {projectDetails?.worlds ? (
              <>
                <div className="wordls-list">
                  <h4>MUNDOS</h4>
                  <div>
                    <ul>
                      {projectDetails?.worlds.worlds.map((worlds, i) => (
                        <li key={i}>
                          <span>{worlds}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <img
                  className="image"
                  data-aos="slide-left"
                  src={`${projectDetails.worldsImgage?.data?.attributes?.url}`}
                  alt="worlds"
                ></img>
              </>
            ) : (
              ""
            )}
          </section>
          <section className="sect-five gallery">
            {projectDetails?.gallery?.data
              ? projectDetails?.gallery?.data.map((step, i) => (
                  <div
                    className="gallery-pic"
                    style={{ background: `url(${step.attributes.url})` }}
                    key={i}
                  ></div>
                ))
              : ""}
          </section>
        </div>
      ) : (
        <ErrorPage />
      )}

      <BackToTop />
    </>
  );
};

export default Home;
