import Filter from "../Filter/Filter";
import "./Filters.css";
import { useState, useEffect } from "react";

const Filters = ({
  setActiveFilter,
  activeFilter,
  setFilteredProjects,
  allProjects,
}) => {
  const [allTags, setTags] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const buscarTags = (dato) => {
    fetch("https://dezetapi-production.up.railway.app/api/tags", {
      method: "get",
      headers: new Headers({
        Authorization:
          "Bearer 1d82fb7af892faae9a5adf1d277909f1404b02e29d71d572fefad3e4d99ab8f427c35930f41e8d56f1d083afac263bc9512bdc80424ea0df90d86d83be48d7d1faaa3160a554c6905b0c0fd47e9e2458678d2a4e3ea418d711067b129b1ff6f5594337b0be839beb0c1bf95d8ce345546615eb9c61db918156d6b320d87759aa",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
      }),
    })
      .then((r) => r.json())
      .then((res) => {
        setTags(res.data);
        setLoading(false);
      });
  };

  useEffect(() => {
    buscarTags();
  }, []);

  useEffect(() => {
    if (activeFilter === "Todos") {
      setFilteredProjects(allProjects);
      return;
    }
    //const filtered = allProjects.filter(project => Object.values(project.attributes.tags?.data).reduce((acc, tag) => tag.attributes.tag) === activeFilter);
    const filtered = allProjects.filter((project) =>
      project.attributes.tags?.data.some(
        (tag) => tag.attributes.tag === activeFilter
      )
    );

    setFilteredProjects(filtered);
    //const filtered = allProjects.filter(project => project.attributes.tags.data.includes());
  }, [activeFilter]);
  const allProjectsTag = { id: 0, attributes: { tag: "Todos" } };

  return (
    <div className="filters">
      {isLoading ? (
        // <Loading />
        ""
      ) : (
        <>
          <Filter
            key={0}
            {...allProjectsTag}
            activeFilter={activeFilter}
            setActiveFilter={setActiveFilter}
          />
          {allTags.length !== 0 ? (
            allTags.map((listaTags) => (
              <Filter
                key={listaTags.id}
                {...listaTags}
                activeFilter={activeFilter}
                setActiveFilter={setActiveFilter}
              />
            ))
          ) : (
            <p>No hay resultados</p>
          )}
        </>
      )}
    </div>
  );
};

export default Filters;
