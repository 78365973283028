import React from 'react'
import './Loading.css';

const Loading = () => {
  return (
    <section className='loading'>
      <div className='data'>
        <h4 className='aboutTitle'>Cargando</h4>
      </div>
      <div className='blueNexus'></div>
    </section>
  )
}

export default Loading