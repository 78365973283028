import './App.css';
import Home from './Components/Home/Home'
import ExpandedProject from './Components/ExpandedProject/ExpandedProject'
import { Route ,Routes,useParams} from 'react-router-dom';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import ErrorPage from './Components/ErrorPage/ErrorPage';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";



function App() {
  const Ampliacion = () => {
    const { proyecto } = useParams()
    return(
    <ExpandedProject project={proyecto}/>
    )
  }
  const firebaseConfig = {
    apiKey: "AIzaSyBuCRQ4Od-Iz3ZFnWWkvYFrPI18XmBuzXc",
    authDomain: "dezeta-af105.firebaseapp.com",
    projectId: "dezeta-af105",
    storageBucket: "dezeta-af105.appspot.com",
    messagingSenderId: "564015527208",
    appId: "1:564015527208:web:0e94091c290af3aff54c1d",
    measurementId: "G-VTNX6WG7Q4"
  };
  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  return (
    <>
    <Header />
    <Routes>
      <Route path='/' element={<Home />}/>
      <Route path="/project/:proyecto" element={<Ampliacion />}/>
      <Route path="*" element={<ErrorPage />} />
    </Routes>
    <Footer />
    </>
  );
}

export default App;
