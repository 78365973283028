import './ErrorPage.css';
const ErrorPage = () => {
  document.title = `Página no encontrada - Dario Zalovich`
  return (
    <section className='mainBanner error'>
        <div className='darioZalovich'>
        <h2>¿</h2>
        <div className='blueNexus-dashed'></div>
        <h2>?</h2>
      </div> 
       <div className='data'>
          <h4 className='aboutTitle'>Página no encontrada</h4>
        </div>
    </section>
  )
}

export default ErrorPage