import { Button } from "react-bootstrap";
import "./Filter.css";

const Filter = ({ id, attributes, setActiveFilter, activeFilter }) => {
  return (
    <Button
      className={activeFilter === attributes.tag ? "filter active" : "filter"}
      bg="primary"
      onClick={() => setActiveFilter(attributes.tag)}
    >
      {attributes.tag}
    </Button>
  );
};

export default Filter;
